@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap');
.App {
  text-align: center;
  overflow: hidden;
  /* background-color: rgba(255,255,255, 0.5); */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-font {
  font-family: 'Baloo Tammudu 2', cursive;
}

.stat-font {
  font-family: 'Roboto Mono', monospace;
}

.rink-color-change-btn {
  width: 60px;
  height: 60px;
  border-radius: 9px;
  margin: 25px;
}

.title-font {
  font-family: 'Poiret One', cursive;
}

#shot-description {
  /* background-color: #282c34; */
  color: lightcyan;
  font-family: 'Baloo Tammudu 2', cursive;
  padding: 7px;
  border-radius: 8px;
  margin-bottom: 100px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-table-content {
  /* background: #282c34; */
  background: rgb(24,38,54);
}

.ant-table-cell {
  font-family: 'Baloo Tammudu 2';
  color: lightcyan
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #224287;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
